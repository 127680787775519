/* eslint-disable react-hooks/exhaustive-deps */
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Typography } from '@mui/material';
import AssigneeDropdown from "components/AssigneeDropdown/AssigneeDropdown";
import CustomDatePicker from "components/CustomDatePicker";
import CustomTimeRangePicker from 'components/CustomTimeRangePicker';
import Warning from 'components/Modal/WarningModal/Warning';
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import _ from 'lodash';
import moment from 'moment';
import AddressDropdown from 'pages/SchedulePages/ScheduleAppointment/ProjectAddress/AddressDropdown';
import ProjectAddress from 'pages/SchedulePages/ScheduleAppointment/ProjectAddress/Index';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSafeValue } from 'utils';
import uuid4 from 'uuid4';
import ArchitecturalOverview from './ArchitecturalOverview';
import AutomotiveOverview from './AutomotiveOverview';
import Styles from "./ScheduleAppointment.module.scss";
import { useAppSelector } from 'redux/hooks';

function ScheduleAppointment({
  state,
  handleUpdate,
  handleAppointmentUpdate,
  resetQuickQuoteModel,
  appointment,
  fromLongQuote = false,
  validationError,
  isPastDateAllowed,
  setIsPastDateAllowed,
  handleScheduleAppointment
}: any) {
  const scheduleBooking = state;
  const { jobs, contact } = scheduleBooking;
  const navigate = useNavigate();
  const [openProjectLocationModal, setOpenProjectLocationModal] = useState<boolean>(false)
  const [appointmentData, setAppointmentData] = useState(appointment)
  const userData: any = useAppSelector((state) => state.auth.entities);
  const defaultJobDuration = getSafeValue(userData, 'organisation.calendar_default_settings.default_job_duration', '00:15')
  const isMobileJob = getSafeValue(scheduleBooking, 'jobs[0].mobile_install', false)

  const handleChange = (name: any, value: any) => {
    handleAppointmentUpdate(name, value);
  };

  const onTimeChange = (name: string, value: any) => {
    handleAppointmentUpdate(name, moment(value, 'hh:mm A').format('YYYY-MM-DDTHH:mm'))
    if (name === 'start_time') {
      const [addHours, addMinutes] = defaultJobDuration.split(':').map(Number);
      const endTime = moment(value, 'hh:mm A').add(addHours, 'hours').add(addMinutes, 'minutes').format('YYYY-MM-DDTHH:mm')
      handleAppointmentUpdate('end_time', endTime)
    }
  }

  useEffect(() => {
    if ((jobs[0]?.type === 'Architectural' && jobs[0]?.service_type === 'Consultation') || isMobileJob === true) {
      setAppointmentData({ ...appointment, location: _.get(contact, 'address', {}) });
    } else {
      setAppointmentData(appointment);
    }
  }, [appointment]);

  const handleViewCalendar = () => {
    resetQuickQuoteModel();
    navigate(`/schedule/calendar?project_id=${jobs[0]?.id}`)
  }

  const handleProjectLocation = (data: any) => {
    handleUpdate(0, 'mobile_install', data.mobile_install)
    handleAppointmentUpdate('location', data.location);
  }

  const handleAppointmentChange = (address: any) => {
    handleUpdate(0, 'mobile_install', getSafeValue(address, 'mobile_install', false))
    handleAppointmentUpdate('location', address);
  }

  const [minDate, setMinDate] = useState(new Date(getSafeValue(appointmentData, 'date', moment().format('YYYY-MM-DD'))))

  return (
    <>
      {fromLongQuote === false ?
        <Box className={Styles.scheduleWrapper} mt={2}>
          <Box className={Styles.dialogContentCardHeader} display={'flex'} justifyContent={'space-between'}>
            <Typography variant="subTitle1" >Schedule Appointment</Typography>
            <Button className={Styles.calendarLink} onClick={handleViewCalendar}>
              View Calendar
              <KeyboardArrowRightIcon />
            </Button>
          </Box>
          <Box className={Styles.scheduleTiming}>
            <Box className={Styles.scheduleDuration}>
              <CustomDatePicker
                selected={new Date(appointmentData.date)}
                dateFormat="dd MMM yyyy"
                onChange={(e: any) => handleChange('date', moment(e).format('YYYY-MM-DDTHH:mm'))}
                minDate={minDate}
              />
              <Box marginLeft={'16px'}>
                <CustomTimeRangePicker
                  startTime={appointmentData.start_time}
                  endTime={appointmentData.end_time}
                  onStartTimeChange={(time: any) => onTimeChange('start_time', time)}
                  onEndTimeChange={(time: any) => onTimeChange('end_time', time)}
                  hideEndTime={appointmentData.allDay}
                  onAllDayChange={(event: any) => handleChange('allDay', event.target.checked)}
                />
                <ErrorMessage error={_.get(validationError, ['startsAt'], '')} />
                <ErrorMessage error={_.get(validationError, ['endsAt'], '')} />
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <AssigneeDropdown teamMember={appointmentData?.assignee}
                  onChange={(value: any) => handleChange('assignee', value)} />
                <ErrorMessage error={_.get(validationError, ['assignee["0"]'], '')} />
              </Box>
            </Box>
          </Box>

          <Box className={Styles.flexWrapper}>
            {appointmentData &&
              <AddressDropdown
                handleUpdateLocation={(location: any) => {
                  handleAppointmentChange(location)
                }}
                location={getSafeValue(appointmentData, 'location', {})}
                contact_id={contact.id}
                is_mobile={isMobileJob}
                fullWidth={true}
              />}
          </Box>
        </Box> : <Box>
          <Box className={Styles.dialogContentCardHeader}>
            <Typography variant="subTitle1">Schedule Appointment</Typography>
          </Box>
          <Box display={'flex'} justifyContent={'start'} gap={'8px'} marginTop={'18px'}>
            <Box display={'flex'} flexDirection={'column'}>
              <AssigneeDropdown 
                teamMember={appointmentData?.assignee} 
                onChange={(value: any) => handleChange('assignee', value)} 
                marginLeft={'0'} 
              />
              <ErrorMessage error={_.get(validationError, ['assignee["0"]'], '')} />
            </Box>
            <CustomDatePicker
              selected={new Date(appointmentData.date)}
              dateFormat="dd MMM yyyy"
              onChange={(e: any) => handleChange('date', moment(e).format('YYYY-MM-DDTHH:mm'))}
              minDate={minDate}
            />
            <Box display={'flex'} flexDirection={'column'}>
              <CustomTimeRangePicker
                startTime={appointmentData.start_time}
                endTime={appointmentData.end_time}
                onStartTimeChange={(time: any) => onTimeChange('start_time', time)}
                onEndTimeChange={(time: any) => onTimeChange('end_time', time)}
                hideEndTime={appointmentData.allDay}
                onAllDayChange={(event: any) => handleChange('allDay', event.target.checked)}
              />
              <ErrorMessage error={_.get(validationError, ['startsAt'], '')} />
              <ErrorMessage error={_.get(validationError, ['endsAt'], '')} />
            </Box>
            <Button className={Styles.calendarLink} onClick={handleViewCalendar}>
              View Calendar
              <KeyboardArrowRightIcon />
            </Button>
          </Box>
          <Box display={'flex'} marginTop={'8px'}>
            <AddressDropdown
              handleUpdateLocation={(location: any) => {
                handleAppointmentChange(location)
              }}
              location={getSafeValue(appointmentData, 'location', {})}
              contact_id={contact.id}
              is_mobile={isMobileJob}
              fullWidth={true}
            />
          </Box>
        </Box>}

      {jobs.map((job: any, index: number) =>
        <div key={uuid4()}>
          {job.type === 'Automotive' &&
            <AutomotiveOverview job={job} contact={contact} index={index} fromLongQuote={fromLongQuote} />
          }
          {job.type === 'Architectural' &&
            <ArchitecturalOverview job={job} contact={contact} index={index} fromLongQuote={fromLongQuote} />
          }
        </div>
      )}
      <Warning
        title="Warning"
        content="You are booking for a date that has already passed. Are you sure you want to book this job for a past date?"
        open={isPastDateAllowed}
        close={() => setIsPastDateAllowed(false)}
        handle={() => handleScheduleAppointment(false)}
        darkButton={true}
      />
      {openProjectLocationModal === true &&
        <ProjectAddress
          onPopupClose={() => setOpenProjectLocationModal(false)}
          handleUpdateLocation={handleProjectLocation}
          location={appointmentData?.location}
          contact_id={contact.id}
        />}
    </>
  );
}

export default ScheduleAppointment;
