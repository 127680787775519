import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Typography
} from '@mui/material';
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import _ from 'lodash';
import React, { useState } from 'react';
import { HiOutlineChevronDown } from "react-icons/hi";
import { useAppSelector } from 'redux/hooks';
import { getInstallAreas } from 'redux/selectors/optionSelectors';
import uuid4 from 'uuid4';
import BpCheckbox from "../../../components/Ui/CheckBox/BpCheckbox";
import TextInput from "../../../components/Ui/TextInput/TextInput";
import styles from "./Application.module.scss";
import SelectFilm from './FilmSelect';
import PriceBreakdown from "./PriceBreakdown";

interface ApplicationDetailProps {
  handleUpdateService: any,
  job: any,
  index: number,
  handleUpdateJob: any,
  validationError?: any,
  messageForCustomer?: any
}

function ApplicationDetail({
  handleUpdateService, job, index, handleUpdateJob, validationError, messageForCustomer
}: ApplicationDetailProps) {
  const applicationAreas: any = useAppSelector(getInstallAreas);

  const [installArea, setInstallArea] = useState<any>(applicationAreas)

  const {
    services,
    tax_exempt,
    mobile_install,
    price_options,
    mobile_price = ''
  } = job;

  const {
    applications = [],
    type,
    price = '',
    project_service_note = {},
    service_title,
    discount,
    discount_price,
    film_removal_option,
    add_notes
  } = services[index];

  const [priceBreakdown, setPriceBreakdown] = useState(false);
  const [inputValue, setInputValue] = useState('')

  const handleUpdate = (name: string, value: any) => {
    handleUpdateService(index, name, value);
  };

  const handleUpdateApplication = (application: any) => {
    const appToUpdate = 'applications';
    handleUpdate(appToUpdate, application);
  };

  const handleFilmChange = (appIndex: number, newApplication: any) => {
    const updatedApplications = applications?.map((item: any, i: number) => (i === appIndex ? newApplication : item)) || [];
    handleUpdateApplication(updatedApplications);
  };

  const handleAddApplication = (application: any) => {
    let isNewArea = true;
    const clashing_area = application.clashingAreas
    const updatedInstallArea = installArea.map((area: any) => ({
      ...area,
      disabled: clashing_area.includes(area.id)
    }))
    setInstallArea(updatedInstallArea)

    const updatedApplications = applications?.map((item: any) => {
      if (application.area === item.area) {
        isNewArea = false;
        return item;
      }
      return item;
    }) || [];

    if (isNewArea) {
      delete application.id
      updatedApplications.push(application);
      handleUpdateApplication(updatedApplications);
    }
    setInputValue('')
  };

  const handleRemoveApplication = (application: any) => {
    const newApps = applications.filter((item: any) => application.area !== item.area);
    const clashing_area = application.clashingAreas
    const updatedInstallArea = installArea.map((area: any) => ({
      ...area,
      disabled: area.disabled === true && !clashing_area.includes(area.id)
    }))
    setInstallArea(updatedInstallArea)
    handleUpdateApplication(newApps)
  };

  const handleUpdateNote = (text: any) => {
    const note = { ...project_service_note, text };
    handleUpdate('project_service_note', note);
  };

  const onPriceChange = (name: any, value: any, type = '') => {
    if (value < 0) {
      return false
    }
    if (type === 'job') {
      handleUpdateJob(name, value);
    } else {
      handleUpdate(name, value);
    }
  }

  const handleMobileInstallChange = (event: any) => {
    const { checked } = event.target
    handleUpdateJob('mobile_install', checked)
    if (checked === false) {
      onPriceChange('mobile_price', 0, 'job')
    }
  }

  return (
    <div className={styles.serviceWrapper}>
      {type !== 'Other' &&
        <div className={styles.applicationAreaWrapper}>
          <div className={styles.serviceInputWrapper}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
              <Typography variant='subtitle1' fontWeight={600}>{type}</Typography>
              <FormGroup row>
                <FormControlLabel
                  className={styles.BpCheckbox}
                  control={<BpCheckbox
                    checked={tax_exempt}
                    value={tax_exempt}
                    onChange={e => handleUpdateJob('tax_exempt', e.target.checked)} />}
                  label='Tax Exempt'
                />
                <FormControlLabel
                  className={styles.BpCheckbox}
                  control={<BpCheckbox
                    checked={mobile_install}
                    value={mobile_install}
                    onChange={handleMobileInstallChange} />}
                  label='Mobile Install'
                />
              </FormGroup>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Autocomplete
                className='cursor'
                options={installArea}
                getOptionDisabled={(option: any) => option.disabled}
                getOptionLabel={(option: any) => option?.area}
                onChange={(event, value: any) => handleAddApplication({ ...value, film_removal: false })}
                value={null}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => { setInputValue(newInputValue) }}
                fullWidth
                renderInput={(params) => <TextInput
                  {...params}
                  placeholder='Add Install Area'
                  margin='dense'
                  style={{ width: '220px' }}
                  InputProps={{ ...params.InputProps, readOnly: true }}
                />}
                popupIcon={<HiOutlineChevronDown color='#A3A3A3' />}
              />
              <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].applications`], '')} />
            </Box>
          </div>

          <div className={styles.applicationAreaWrapper}>
            {applications?.map((item: any, i: number) => (
              <React.Fragment key={uuid4()}>
                <SelectFilm
                  key={uuid4()}
                  index={i}
                  application={item}
                  handleRemove={handleRemoveApplication}
                  handleChange={handleFilmChange}
                  handleUpdate={handleUpdate}
                  service={services[index]}
                />
                <Box display={'flex'} flexDirection={'column'}>
                  <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].applications[${i}].films[0].film_id`], '')} />
                  <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].applications[${i}].films[0].film_shade_id`], '')} />
                </Box>
              </React.Fragment>
            ))}

            {messageForCustomer && <>
              <Typography variant='subtitle1' mt={2} fontWeight={'600'}>Customer message left on form: </Typography>
              <Typography variant='subtitle1' fontStyle={'italic'}>{messageForCustomer}</Typography>
            </>
            }
            {mobile_install &&
              <Box pt={2} width={'20%'} display={'flex'} flexDirection={'column'}>
                <Typography variant='title2' fontSize={'14px'}>Mobile Price</Typography>
                <TextInput
                  type='number'
                  margin='dense'
                  name='mobile_price'
                  value={mobile_price === 0 ? '' : mobile_price}
                  sx={{ '& .MuiInputBase-input': { paddingLeft: '0 !important' } }}
                  className={styles.priceInput}
                  onChange={(e: any) => onPriceChange('mobile_price', e.target.value, 'job')}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>
                  }}
                />
                <ErrorMessage error={_.get(validationError, ['jobs[0].mobile_price'], '')} />
              </Box>
            }

            <Box pt={2} className={styles.priceWrapper}>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography variant='title2' fontSize={'14px'}>{discount ? 'Before Price' : 'Total Price'}</Typography>
                <TextInput
                  type='number'
                  margin='dense'
                  name='price'
                  value={price === 0 ? '' : price}
                  sx={{ '& .MuiInputBase-input': { paddingLeft: '0 !important' } }}
                  className={styles.priceInput}
                  onChange={(e: any) => onPriceChange('price', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>
                  }}
                />
              </Box>

              {discount &&
                <Box pl={2} display={'flex'} flexDirection={'column'}>
                  <Typography variant='title2' fontSize={'14px'}>Discounted Price</Typography>
                  <TextInput
                    type='number'
                    margin='dense'
                    name='discount_price'
                    sx={{ '& .MuiInputBase-input': { paddingLeft: '0 !important' } }}
                    className={styles.discountPriceInput}
                    value={discount_price}
                    onChange={(e: any) => onPriceChange('discount_price', e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                  />
                </Box>
              }
              <Box pl={2}>
                <Typography onClick={e => setPriceBreakdown(true)} variant='subtitle2' className={styles.priceBreakdown}>Price Breakdown</Typography>
              </Box>
            </Box>

            <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].price`], '')} />
            <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].discount_price`], '')} />

            {add_notes &&
              <Box pt={2}>
                <Typography variant='subtitle2'>Customer Note (Private)</Typography>
                <TextInput
                  placeholder='Add Your Notes Here'
                  value={project_service_note?.text}
                  name='notes'
                  onChange={(e: any) => handleUpdateNote(e.target.value)}
                  margin='dense'
                  fullWidth
                  multiline
                  minRows={4}
                />
                <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].project_service_note`], '')} />
              </Box>
            }

            <Box pt={2}>
              <FormGroup row>
                <FormControlLabel
                  className={styles.BpCheckbox}
                  control={<BpCheckbox
                    checked={price_options}
                    value={price_options}
                    onChange={e => handleUpdateJob('price_options', e.target.checked)} />}
                  label='Additional Options'
                />

                {price_options &&
                  <>
                    <FormControlLabel
                      control={<BpCheckbox
                        checked={film_removal_option}
                        value={film_removal_option}
                        onChange={e => handleUpdate('film_removal_option', e.target.checked)} />}
                      label='Film Removal'
                    />

                    <FormControlLabel
                      control={<BpCheckbox
                        checked={discount}
                        value={discount}
                        onChange={e => handleUpdate('discount', e.target.checked)} />}
                      label='Discounted Price'
                    />
                    <FormControlLabel
                      control={<BpCheckbox
                        checked={add_notes}
                        value={add_notes}
                        onChange={e => handleUpdate('add_notes', e.target.checked)} />}
                      label='Add Notes'
                    />
                  </>
                }
              </FormGroup>
            </Box>
          </div>
        </div>
      }

      {type === 'Other' &&
        <Box className={styles.applicationWrapper}>
          <Typography variant='subtitle1' fontWeight={600}>Other Service </Typography>
          <Box>
            <Box className={styles.inputWrapper}>
              <Typography variant='subtitle2'>Service Title</Typography>
              <TextInput
                placeholder='Service Title'
                defaultValue={service_title}
                name='service_title'
                onChange={(e: any) => handleUpdate('service_title', e.target.value)}
                margin='dense'
                fullWidth
              />
              <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].service_title`], '')} />
            </Box>
            <Box display={'flex'} alignItems={'flex-end'}>
              <Box className={styles.inputWrapper} style={{ flexDirection: 'column', width: '30%' }}>
                <Typography variant='subtitle2'>Total Price</Typography>
                <TextInput
                  type='number'
                  margin='dense'
                  name='price'
                  defaultValue={price}
                  onChange={(e: any) => onPriceChange('price', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>
                  }}
                />
                <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].price`], '')} />
              </Box>
              <Box pl={2}>
                <Typography onClick={e => setPriceBreakdown(true)} variant='subtitle2' className={styles.priceBreakdown}>Price Breakdown</Typography>
              </Box>
            </Box>
            <Box className={styles.inputWrapper}>
              <Typography variant='subtitle2'>Customer Note (Private)</Typography>
              <TextInput
                placeholder='Customer Note (Private)'
                value={project_service_note?.text}
                name='notes'
                onChange={(e: any) => handleUpdateNote(e.target.value)}
                margin='dense'
                fullWidth
                multiline
                minRows={4}
              />
              <ErrorMessage error={_.get(validationError, [`jobs[0].services[${index}].project_service_note`], '')} />
            </Box>
          </Box>
        </Box>
      }

      {priceBreakdown === true &&
        <PriceBreakdown
          priceBreakdown={priceBreakdown}
          setPriceBreakdown={setPriceBreakdown}
          mobile_install={mobile_install}
          mobile_price={mobile_price}
          job={job}
        />
      }
    </div>
  );
}

export default ApplicationDetail;
