/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';
import ContactAPI from 'api/resources/contact';
import classNames from 'classnames';
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import useDiscoveryTags from 'hooks/useDiscoveryTags';
import _ from 'lodash';
import SuggestedContactPopup from 'pages/QuickQuote/SuggestedContactPopup';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { refreshRequest } from 'redux/reducers/contactReducer';
import { setContact, updateContact, updateQuoteJob } from 'redux/reducers/quickQuoteReducer';
import { getQuickQuote } from 'redux/selectors/quickQuoteSelector';
import { getSafeValue } from 'utils';
import BpCheckbox from "../../components/Ui/CheckBox/BpCheckbox";
import TextInput from "../../components/Ui/TextInput/TextInput";
import styles from "./ContactForm.module.scss";

interface ContactFormProps { errors: any; }

function ContactForm({
  errors: validationError
}: ContactFormProps) {
  const dispatch = useAppDispatch();

  const quickQuote: any = useAppSelector((state) => getQuickQuote(state));
  const { contact } = quickQuote
  const job = _.get(quickQuote, 'jobs[0]', [])

  const [suggestedContacts, setSuggestedContacts] = useState<any>(null);
  const [suggestedContact, setSuggestedContact] = useState<any>(null);

  const userData: any = useAppSelector((state) => state.auth.entities);
  const userState = getSafeValue(userData, 'organisation.address.state_province', '')

  const { discoveryTags, getDiscoveryTag } = useDiscoveryTags();
  const discoveryList = discoveryTags.map((dis: any) => (dis.label))

  const jobAddress: any = _.defaultTo(_.get(job, 'address', { state_province: userState }), { state_province: userState })

  const {
    city = '',
    street_address = '',
    state_province = userState,
    country = 'United States',
    zipcode = '',
    second_line = ''
  } = jobAddress;

  function hasValue(object: any) {
    return Object.keys(object).some(key => {
      const value = object[key];
      return value !== null && typeof value === 'string' && value.trim() !== '';
    });
  }

  useEffect(() => {
    const address = { street_address, zipcode, second_line };
    dispatch(updateContact({ name: 'full_address', value: hasValue(address) }))
  }, [second_line, street_address, zipcode]);

  useEffect(() => {
    getState()
  }, [])

  const handleSuggest = async () => {
    if (contact?.first_name && contact?.last_name) {
      dispatch(refreshRequest());
      const params: any = {
        page: 1,
        per_page: 10,
        "filter[search_name]": `${contact?.first_name} ${contact?.last_name}`,
      };

      const contact_id = _.get(contact, 'id', false)
      if (contact_id !== false) {
        params.contact_id = contact_id
      }

      try {
        const response = await ContactAPI.list(params, 'contacts')
        setSuggestedContacts(response?.data?.data);
        if (response?.data?.data.length > 0) {
          setSuggestedContact(getSafeValue(response, 'data.data[0]', null));
          const address = _.defaultTo(_.get(response?.data?.data, '[0].address', {}), {})
          dispatch(updateQuoteJob({
            index: 0,
            name: 'address',
            value: address
          }))
        }
        dispatch(refreshRequest());
      } catch (error: any) {
        console.log({ error });
      }
    }
  };

  const onSelectContactChange = (event: any) => {
    const selectedContact = suggestedContacts.find((item: any) => item.id === event.target.value);
    setSuggestedContact(selectedContact);
    dispatch(updateQuoteJob({
      index: 0,
      name: 'address',
      value: _.get(selectedContact, 'address', {})
    }))
  };

  const handleUseContact = () => {
    const contact = {
      ...suggestedContact,
      first_name: suggestedContact.first_name,
      last_name: suggestedContact.last_name,
      discoveries: suggestedContact.discovery.map((discovery: any) => (discovery.value)),
      is_business: getSafeValue(suggestedContact, 'is_business', false),
      receive_email: getSafeValue(suggestedContact, 'receive_email', true),
    }
    dispatch(setContact(contact))
    dispatch(updateQuoteJob({
      index: 0,
      name: 'address',
      value: _.get(suggestedContact, 'address', {})
    }))
    setSuggestedContact(null);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target
    dispatch(updateContact({ name, value }))
  };

  const handleDiscoveryChange = (value: any) => {
    dispatch(updateContact({ name: 'discoveries', value: value }))
  }

  const handleChangeAddress = async (name: string, value: any) => {

    let newAddress = { ...job.address, [name]: value };

    if (name === 'state_province') {
      newAddress = { ...newAddress, country: country || 'United States' };
      if (value === null) {
        setCities([])
      } else {
        await getCities(value)
      }
    }
    dispatch(updateQuoteJob({
      index: 0,
      name: 'address',
      value: newAddress
    }))
    dispatch(updateContact({ name: 'address', value: newAddress }))
  };

  const handleCheckBoxChecked = (event: any) => {
    const { name, checked } = event.target
    dispatch(updateQuoteJob({
      index: 0,
      name: name,
      value: checked
    }))

    dispatch(updateContact({ name, value: checked }))
    if (name === 'mobile_install') {
      const address = { city, state_province };
      dispatch(updateContact({ name: 'address', value: address }))
      if (checked === true) {
        dispatch(updateQuoteJob({
          index: 0,
          name: 'address',
          value: address
        }))
      } else {
        dispatch(updateQuoteJob({
          index: 0,
          name: 'address',
          value: {}
        }))
      }
    }
  }

  const [state, setState] = useState([])
  const getState = async () => {
    const allState = await ContactAPI.getState()
    setState(allState.data)
  }

  const [cities, setCities] = useState([])
  const getCities = async (state: any) => {
    const allState = await ContactAPI.getCity(state)
    setCities(allState.data)
  }

  return (
    <Box className={styles.sectionWrappers}>
      <Typography variant='subTitle1'>
        Contact Information
      </Typography>
      <Box gap={1} display={'flex'} flexDirection={'row'}>
        <Box flex={1}>
          <TextInput
            onChange={handleChange}
            value={getSafeValue(contact, 'first_name', '')}
            name="first_name"
            placeholder="First Name"
            margin="dense"
            size="small"
            variant="outlined"
            className={styles.input}
            fullWidth
          />
          <ErrorMessage error={_.get(validationError, ['contact.first_name'], '')} />
        </Box>
        <Box flex={1}>
          <TextInput
            onChange={handleChange}
            value={getSafeValue(contact, 'last_name', '')}
            name="last_name"
            placeholder="Last Name"
            size="small"
            margin="dense"
            variant="outlined"
            onBlur={handleSuggest}
            className={styles.input}
            fullWidth
          />
          <ErrorMessage error={_.get(validationError, ['contact.last_name'], '')} />
        </Box>
      </Box>
      <Box py={1}>
        <FormControlLabel
          control={
            <BpCheckbox
              value={getSafeValue(contact, 'is_business', false)}
              checked={getSafeValue(contact, 'is_business', false)}
              name='is_business'
              onChange={handleCheckBoxChecked}
            />
          }
          label="For A Business"
        />
        <FormControlLabel
          control={
            <BpCheckbox
              checked={getSafeValue(contact, 'mobile_install', false)}
              value={getSafeValue(contact, 'mobile_install', false)}
              onChange={handleCheckBoxChecked}
              name="mobile_install"
            />
          }
          label="Mobile Install"
        />
      </Box>
      {contact?.is_business && (
        <>
          <TextInput
            onChange={handleChange}
            value={getSafeValue(contact, 'company', '')}
            name="company"
            placeholder="Business Name"
            size="small"
            margin="dense"
            className={styles.input}
            fullWidth
          />
          <ErrorMessage error={_.get(validationError, ['contact.company'], '')} />
        </>
      )}
      {contact.mobile_install && (
        <>
          {!contact?.full_address &&
            <Box gap={1} display={'flex'} flexDirection={'row'}>
              <Box flex={1}>
                <Autocomplete
                  options={state}
                  getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                  onChange={(_, value: any) => handleChangeAddress('state_province', getSafeValue(value, 'name', value))}
                  value={getSafeValue(state_province, 'name', state_province)}
                  isOptionEqualToValue={(option: any, value: any) => option.name === value}
                  onInputChange={(_, newInputValue: string) => handleChangeAddress('state_province', newInputValue)}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      size="small"
                      placeholder='State'
                      margin="dense"
                    />
                  )}
                  fullWidth
                  freeSolo
                />
                <ErrorMessage error={_.get(validationError, ['jobs[0].address.state_province'], '')} />
              </Box>
              <Box flex={1}>
                <Autocomplete
                  options={cities}
                  getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                  onChange={(_, value: any) => handleChangeAddress('city', getSafeValue(value, 'name', value))}
                  value={getSafeValue(city, 'name', city)}
                  isOptionEqualToValue={(option: any, value: any) => option.name === value}
                  onInputChange={(_, newInputValue: string) => handleChangeAddress('city', newInputValue)}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      size="small"
                      placeholder='City'
                      margin="dense"
                    />
                  )}
                  fullWidth
                  freeSolo
                />
                <ErrorMessage error={_.get(validationError, ['jobs[0].address.city'], '')} />
              </Box>
            </Box>
          }

          {contact?.full_address && (
            <Box pt={2}>
              <Typography variant='subTitle1'>
                Address
              </Typography>
              <Box>
                <Box>
                  <TextInput
                    size="small"
                    margin="dense"
                    value={street_address}
                    onChange={(e) =>
                      handleChangeAddress(e.target.name, e.target.value)
                    }
                    name="street_address"
                    placeholder="Street Address"
                    fullWidth
                  />
                  <ErrorMessage error={_.get(validationError, ['jobs[0].address.street_address'], '')} />
                </Box>
                <Box gap={1} display={'flex'} flexDirection={'row'}>
                  <Box flex={1}>
                    <TextInput
                      size="small"
                      margin="dense"
                      value={second_line}
                      onChange={(e) =>
                        handleChangeAddress(e.target.name, e.target.value)
                      }
                      name="second_line"
                      placeholder="2nd Line"
                      fullWidth
                    />
                    <ErrorMessage error={_.get(validationError, ['jobs[0].address.second_line'], '')} />
                  </Box>
                  <Box flex={1}>
                    <Autocomplete
                      options={state}
                      getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                      onChange={(_, value: any) => handleChangeAddress('state_province', getSafeValue(value, 'name', value))}
                      onInputChange={(_, newInputValue: string) => handleChangeAddress('state_province', newInputValue)}
                      value={getSafeValue(state_province, 'name', state_province)}
                      isOptionEqualToValue={(option: any, value: any) => option.name === value}
                      renderInput={(params) => (
                        <TextInput
                          {...params}
                          size="small"
                          placeholder='State'
                          margin="dense"
                        />
                      )}
                      fullWidth
                      freeSolo
                    />
                    <ErrorMessage error={_.get(validationError, ['jobs[0].address.state_province'], '')} />
                  </Box>
                </Box>
                <Box gap={1} display={'flex'} flexDirection={'row'}>
                  <Box flex={1}>
                    <Autocomplete
                      options={cities}
                      getOptionLabel={(option) => getSafeValue(option, 'name', option)}
                      onChange={(_, value: any) => handleChangeAddress('city', getSafeValue(value, 'name', value))}
                      onInputChange={(_, newInputValue: string) => handleChangeAddress('city', newInputValue)}
                      value={getSafeValue(city, 'name', city)}
                      isOptionEqualToValue={(option: any, value: any) => option.name === value}
                      renderInput={(params) => (
                        <TextInput
                          {...params}
                          size="small"
                          placeholder='City'
                          margin="dense"
                        />
                      )}
                      fullWidth
                      freeSolo
                    />
                    <ErrorMessage error={_.get(validationError, ['jobs[0].address.city'], '')} />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      size="small"
                      margin="dense"
                      value={zipcode}
                      onChange={(e) =>
                        handleChangeAddress(e.target.name, e.target.value)
                      }
                      name="zipcode"
                      placeholder="Zip"
                      fullWidth
                    />
                    <ErrorMessage error={_.get(validationError, ['jobs[0].address.zipcode'], '')} />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box py={1}>
            <FormControlLabel
              control={
                <BpCheckbox
                  checked={getSafeValue(contact, 'full_address', false)}
                  value={getSafeValue(contact, 'full_address', false)}
                  onChange={handleCheckBoxChecked}
                  name="full_address"
                />
              }
              label="Add full address"
            />
          </Box>
        </>
      )}
      <Box pt={2} className={classNames(styles.fieldWrapper)}>
        <Typography variant='subTitle1'>
          Discovery
        </Typography>
        <Grid container>
          <Grid item md={6}>
            <Autocomplete
              multiple
              options={discoveryList}
              value={getSafeValue(contact, 'discoveries', []).map((discovery: any) => getSafeValue(discovery, 'value', discovery))}
              onChange={(event: any, value: any) => { handleDiscoveryChange(value) }}
              renderOption={(props, option: any) => (
                <Box
                  component="li"
                  className={styles.autoCompletOPtions}
                  {...props}
                >
                  <div className={styles.autoCompletOPtions}>
                    {option}
                    <img src={getDiscoveryTag(option)?.img} alt={getDiscoveryTag(option)?.label} height={'22px'} width={'22px'} />
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  size="small"
                  label="Discovery"
                  margin="dense"
                  InputProps={{ ...params.InputProps }}
                />
              )}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <SuggestedContactPopup
        setSuggestedContact={setSuggestedContact}
        suggestedContact={suggestedContact}
        suggestedContacts={suggestedContacts}
        onSelectContactChange={onSelectContactChange}
        handleUseContact={handleUseContact}
        buttonText="Use This Contact"
      />
    </Box>
  );
}

export default ContactForm;
