import { Box, FormControlLabel, Grid, Typography } from "@mui/material";
import Head from "helper/Head";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { changeBgColor } from "redux/reducers/uiReducer";
import { setPlaceName } from "utils/PlaceName";
import Style from "./CreateLeadForm.module.scss";
import LeadFormIcon from "assets/icon_lead_form.svg";
import TextInput from "components/FormInputs/TextInput/Index";
import SelectDropdown from "components/FormInputs/Select/Index";
import BpCheckbox from "components/Ui/CheckBox/BpCheckbox";
import { MuiColorInput } from 'mui-color-input'
import { GreenButton } from "components/Ui/Button/Button";
import LeadFormAPI from "api/resources/leadForm";
import FormPreview from "../FormPreview";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import InnerLoader from "components/Loading/InnerLoader";
import _ from "lodash";
import { themeColor } from "theme/color";
import useDiscoveryTags from "hooks/useDiscoveryTags";

const discoveryTags = [
  { label: 'Google', value: 'Google' },
  { label: 'Bing', value: 'Bing' },
  { label: 'Website', value: 'Website' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'TikTok', value: 'TikTok' },
  { label: 'Walk In', value: 'Walk In' },
  { label: 'Referral', value: 'Referral' },
]

const categories = [
  { label: 'Automotive', value: 'for_automotive' },
  { label: 'Residential', value: 'for_architectural' },
  { label: 'Commercial', value: 'for_commercial' },
  { label: 'Other', value: 'for_other' },
]

const automotiveServices = [
  'Car Window Tinting',
  'Headlight & Taillight Tinting',
  'Headlight Restoration',
  'Vehicle Wrapping',
  'Paint Protection Film',
  'Ceramic Coatings',
  'Detailing',
  'Dent Removal',
  'Windshield Chip Repair',
  'Windshield Replacement',
  'Windshield Coatings',
  'Other'
]

function CreateLeadForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const urlLocation = useLocation();
  const urlSearch = urlLocation.search;

  const { discoveryTags } = useDiscoveryTags();
  const discovery = discoveryTags.map((dis: any) => ({ label: dis.label, value: dis.value }))

  const [leadFormId, setLeadFormId] = useState<any>(false)

  const [isLoading, setIsLoading] = useState(false)
  const [leadFormDetails, setLeadFormDetails] = useState<any>({
    name: '',
    for_architectural: false,
    for_custom: false,
    for_automotive: false,
    for_commercial: false,
    for_other: false,
    is_additional_options: false,
    tags: [],
    additional_form_settings: [
      {
        setting: "Include Logo In Form",
        value: "",
        type: "select",
        key: 'form_logo',
        options: ['Yes', 'No']
      },
      {
        setting: "Form Display Title",
        placeholder: "Form Display Title",
        value: "",
        type: "textbox",
        key: 'form_display_title'
      },
      {
        setting: "Form Background Color",
        value: "#FFFFFF",
        type: "color",
        key: 'background_color'
      },
      {
        setting: "Form Highlight Color",
        value: "#3E8BFF",
        type: "color",
        key: 'highlight_color'
      },
      {
        setting: "Submission Redirect",
        value: "",
        type: "textbox",
        key: 'redirect_link',
        tooltipText: 'Enter the URL where users will be redirected after successfully submitting the form. For example: https://yourwebsite.com. Leave this field empty if you do not want to redirect users.',
        placeholder: 'https://yourwebsite.com'
      },
      {
        setting: "Bot Prevention reCAPTCHA",
        value: "No",
        type: "select",
        key: 'is_captcha_enabled',
        options: ['Yes', 'No']
      }
    ],
    internal_title: "Lead Form",
    success_message: "Form submitted successfully!",
    services: [],
    status: "Active",
    fields: [{
      name: "automotive_services",
      type: 'select',
      label: "Automotive Service",
      ordering: 1,
      required: true,
      options: []
    }]
  })

  useEffect(() => {
    setPlaceName("Creating New Lead Form");
    dispatch(changeBgColor(themeColor.calendarPageBg));
  }, []);

  useEffect(() => {
    (async () => {
      if (urlSearch) {
        const formId = urlSearch.replace("?", "")
        try {
          setIsLoading(true)
          const response = await LeadFormAPI.view(formId);
          const { data } = response.data
          const formData = {
            name: _.get(data, 'name', ''),
            for_architectural: _.get(data, 'for_architectural', ''),
            for_custom: _.get(data, 'for_custom', ''),
            for_automotive: _.get(data, 'for_automotive', ''),
            for_commercial: _.get(data, 'for_commercial', ''),
            for_other: _.get(data, 'for_other', ''),
            is_additional_options: _.get(data, 'is_additional_options', ''),
            tags: _.get(data, 'tags', ''),
            additional_form_settings: _.get(data, 'additional_form_settings', []),
            fields: _.get(data, 'fields', []).length === 0 ? [{
              name: "automotive_services",
              type: 'select',
              label: "Automotive Service",
              ordering: 1,
              required: true,
              options: []
            }] : _.get(data, 'fields', []),
          }

          console.log(formData)
          setLeadFormDetails(formData)
          const serviceOptions = getServiceOptions()
          setPreviewAutomotiveServices(serviceOptions)
          setIsLoading(false)
        } catch (e) {
          console.log(e);
          setIsLoading(false)
        }
        setLeadFormId(formId);
      }
    })();
  }, [urlSearch]);

  const handleChange = (value: any, name: string) => {
    setLeadFormDetails({
      ...leadFormDetails,
      [name]: value
    })
  }

  const handleCheckbox = (e: any) => {
    const { name, checked } = e.target
    setLeadFormDetails({
      ...leadFormDetails,
      [name]: checked
    })
  }

  const [previewAutomotiveServices, setPreviewAutomotiveServices] = useState<any>([])

  const handleServiceChange = (e: any) => {
    const { value } = e.target

    const fields = leadFormDetails.fields;
    const serviceOptions = fields.find((field: any) => field.name === 'automotive_services')
    const options = [..._.get(serviceOptions, 'options', [])]
    const checkValueAlreadyExistInServiceOptions = options.findIndex((service: any) => service.value === value)

    const updatedServices = (checkValueAlreadyExistInServiceOptions === -1) ? [...options, { label: value, value }] : options.filter((service: any) => service.value !== value)

    const updatedFields = fields.map((field: any) => field.name === 'automotive_services' ? {
      ...field,
      options: updatedServices
    } : field)

    setLeadFormDetails({
      ...leadFormDetails,
      fields: updatedFields
    })

    setPreviewAutomotiveServices(updatedServices)
  }

  const handleFormSubmit = async () => {
    try {
      setIsLoading(true)
      if (leadFormId === false) {
        await LeadFormAPI.create(leadFormDetails)
        toast.success('Lead form successfully created.')
      } else {
        const data = { ...leadFormDetails, id: leadFormId }
        await LeadFormAPI.update(data)
        toast.success('Lead form successfully updated.')
      }
      setIsLoading(false)
      navigate(`/leads/forms`);
    } catch (error: any) {
      setIsLoading(false)
      const errorMessage = error?.response?.data?.message;
      toast.error(errorMessage);
    }
  }

  const handleAdditionalSettingChange = (value: string, name: string) => {
    const updateSetting = leadFormDetails.additional_form_settings.map((setting: any) => (setting.key === name ? {
      ...setting, value
    } : setting))
    setLeadFormDetails({
      ...leadFormDetails,
      additional_form_settings: updateSetting
    })
  }

  const checkServiceCheckedOrNot = (value: string) => {
    const serviceOptions = _.get(leadFormDetails, 'fields', []).find((field: any) => field.name === 'automotive_services')
    const options = [..._.get(serviceOptions, 'options', [])]

    const updatedServices = (options.findIndex((service: any) => service.value === value))
    return updatedServices !== -1
  }

  const getServiceOptions = () => {
    const serviceOptions = _.get(leadFormDetails, 'fields', []).find((field: any) => field.name === 'automotive_services')
    const options = [..._.get(serviceOptions, 'options', [])]
    return options
  }

  return (
    <Box>
      <Head name="Creating New Lead Form" />
      <Box>
        {isLoading && <InnerLoader />}
        <Grid container rowSpacing={2} columnSpacing={2.5}>
          <Grid item xs={12} sm={5} md={4} lg={3.5}>
            <Box className={Style.formRow} p={'0 !important'}>
              <Box
                sx={{
                  borderBottomLeftRadius: `${leadFormDetails.is_additional_options ? 0 : 10} !important`,
                  borderBottomRightRadius: `${leadFormDetails.is_additional_options ? 0 : 10} !important`,
                  padding: '34px'
                }}>
                <Typography variant="title" className={Style.sectionTitle}>
                  <img src={LeadFormIcon} alt="Lead form icon" />Lead Form Details
                </Typography>
                <Box mb={2.5}>
                  <TextInput
                    name="name"
                    label="From Name"
                    value={leadFormDetails.name}
                    onChange={(e: any) => handleChange(e.target.value, e.target.name)}
                    placeholder="Form Name"
                  />
                </Box>
                <Box mb={2.5}>
                  <SelectDropdown
                    name="tags"
                    label="Discovery Tag(s)"
                    value={leadFormDetails.tags}
                    options={discovery}
                    onChange={(e: any) => handleChange([e.target.value], e.target.name)}
                  />
                </Box>
                <Box mb={leadFormDetails.is_additional_options === false ? 2.5 : 0}>
                  <FormControlLabel control={
                    <BpCheckbox
                      checked={leadFormDetails.is_additional_options}
                      onChange={handleCheckbox}
                      name="is_additional_options"
                    />
                  } label="Additional Options" />
                </Box>
                {leadFormDetails.is_additional_options === false && <GreenButton onClick={handleFormSubmit}>{leadFormId === false ? 'Create' : 'Update'} Lead Form</GreenButton>}
              </Box>
              {leadFormDetails.is_additional_options === true &&
                <Box
                  sx={{
                    borderTopLeftRadius: '0 !important',
                    borderTopRightRadius: '0 !important',
                    borderTop: '1px solid rgba(129, 129, 129, 0.2)',
                    padding: '34px',
                  }}>
                  <Typography variant="title" className={Style.sectionTitle}>Additional Form Settings</Typography>
                  {leadFormDetails.additional_form_settings.map((additional_setting: any) => (
                    <Box mb={2.5}>
                      {additional_setting.type === 'textbox' &&
                        <TextInput
                          name={additional_setting.key}
                          label={additional_setting.setting}
                          value={additional_setting.value}
                          onChange={(e: any) => handleAdditionalSettingChange(e.target.value, e.target.name)}
                          placeholder={additional_setting.key === 'redirect_link' ? 'https://yourwebsite.com' : additional_setting.placeholder}
                          tooltipText={additional_setting.key === 'redirect_link' ? 'Enter the URL where users will be redirected after successfully submitting the form. For example: https://yourwebsite.com. Leave this field empty if you do not want to redirect users.' : additional_setting.tooltipText}
                          previewLink={additional_setting.key === 'redirect_link' ? additional_setting.value : ''}
                        />
                      }
                      {additional_setting.type === 'color' &&
                        <Box mb={0.5} display={'flex'} flexDirection={'column'}>
                          <Typography variant='title2' component='label' className={Style.inputLabel}>{additional_setting.setting}</Typography>
                          <MuiColorInput
                            className='color-input'
                            value={additional_setting.value}
                            onChange={(value) => handleAdditionalSettingChange(value, additional_setting.key)}
                            format="hex"
                          />
                        </Box>
                      }
                      {additional_setting.type === 'select' &&
                        <SelectDropdown
                          name={additional_setting.key}
                          label={additional_setting.setting}
                          value={additional_setting.value}
                          options={additional_setting.options.map((option: any) => ({ label: option, value: option }))}
                          onChange={(e: any) => handleAdditionalSettingChange(e.target.value, e.target.name)}
                        />
                      }
                    </Box>
                  ))}
                  <GreenButton onClick={handleFormSubmit}>{leadFormId === false ? 'Create' : 'Update'} Lead Form</GreenButton>
                </Box>
              }
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={8} lg={8.5}>
            <Box className={Style.formRow}>
              <Typography variant="title" className={Style.sectionTitle}>Categories Included</Typography>
              <Box className={Style.categories} mb={'35px'}>
                {categories.map((category: any, index: number) => (
                  <FormControlLabel className={Style.checkboxLabel} key={`category_${index}`} control={
                    <BpCheckbox
                      checked={leadFormDetails[category.value]}
                      onChange={(e: any) => handleChange(e.target.checked, category.value)}
                      value={category.label}
                      name="categories"
                    />
                  } label={category.label} />
                ))}
              </Box>
              {(leadFormDetails.for_automotive === true) && <>
                <Typography variant="title" className={Style.sectionTitle}>Automotive Services</Typography>
                <Box className={Style.services} display={'flex'} gap={'16px'} flexWrap={'wrap'}>
                  {automotiveServices.map((service: any, index: number) => (
                    <FormControlLabel className={Style.checkboxLabel} key={`service_${index}`} control={
                      <BpCheckbox
                        checked={checkServiceCheckedOrNot(service)}
                        onChange={(e: any) => handleServiceChange(e)}
                        value={service}
                        name="services"
                      />
                    } label={service} />
                  ))}
                </Box>
              </>}
            </Box>
            <FormPreview publicForm={false} leadFormDetails={leadFormDetails} previewAutomotiveServices={previewAutomotiveServices} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CreateLeadForm;
