import { FormControl, TextField } from "@mui/material";
import InputTooltip from "components/Ui/InputTooltip/InputTooltip";
import { ChangeEventHandler } from "react";
import classes from "./TextInput.module.scss";
import { LinkButton } from "components/Ui/Button/Button";

interface IFTextField {
  label?: string;
  value?: string;
  name: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  placeholder?: string;
  multiline?: boolean;
  rows?: number
  inputProps?: any,
  tooltipText?: string
  previewLink?: string
}

const TextInput = ({
  label = '',
  value,
  name,
  onChange,
  placeholder,
  multiline = false,
  rows = 0,
  inputProps,
  tooltipText,
  previewLink
}: IFTextField) => {
  return <FormControl fullWidth variant="outlined">
    {label !== '' &&
      <label className={classes.inputLabel} htmlFor={name}>
        <span className={classes.inputLabelText}>{label} {tooltipText && <InputTooltip text={tooltipText} />}</span>
        {previewLink && <LinkButton
          className="btn-link"
          sx={{ fontSize: '12px' }}
          onClick={() => window.open(previewLink, "_blank")}
          title={previewLink}
        >Preview</LinkButton>}
      </label>
    }
    <TextField
      id={name}
      className={classes.TextField}
      variant="outlined"
      defaultValue={value}
      value={value}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      InputProps={inputProps}
    />
  </FormControl>;
};

export default TextInput;
