import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Style from "./CustomDatePicker.module.scss";

const CustomDatePicker = (props: any) => {
  const calculateWidth = () => {
    const date = moment(props.selected).format(props.dateFormat || "DD MMMM yyyy");
    return ((date.length + 1) * 9) + 24
  }

  return (<DatePicker
    className={Style.datepicker}
    {...props}
    customInput={<input style={{ width: `${calculateWidth()}px` }} />}
    popperPlacement="bottom-start"
  />
  );
};

export default CustomDatePicker
