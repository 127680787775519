import { Box } from "@mui/material";
import InnerLoader from "components/Loading/InnerLoader";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

const PublicLayout = ({ children }: any) => {
  const isLoading = useAppSelector((state) => state.loading.isInnerLoading);
  const bgColor = useAppSelector((state) => state.ui.bgColor);
  
  return (
    <Box bgcolor={bgColor} className="bodyContainer">
      {isLoading && <InnerLoader />}
      <Outlet />
    </Box>
  );
};

export default PublicLayout;
