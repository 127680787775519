import LeadApi from 'api/resources/leads';
import { useCallback, useEffect, useState } from 'react';

interface DiscoveryTag {
  value: string;
  img: string;
}

const useDiscoveryTags = () => {
  const [discoveryTags, setDiscoveryTags] = useState<any[]>([]);

  // Fetch discovery types
  const getDiscoveryTypes = useCallback(async () => {
    try {
      const response = await LeadApi.getDiscoveryTypes();
      setDiscoveryTags(response.data);
    } catch (error) {
      console.error('Error fetching discovery tags:', error);
    }
  }, []);

  useEffect(() => {
    getDiscoveryTypes();
  }, [getDiscoveryTypes]);

  // Component to display discovery tag image
  const getDiscoveryTag: any = (value: any) => {
    const tag = discoveryTags.find((item) => item.value === value);
    return tag
  };

  return { discoveryTags, getDiscoveryTag };
};

export default useDiscoveryTags;
